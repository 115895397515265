<template>
	<div>
		<img class="banner" style="width: 100%;" src="../assets/banner.png" alt="">
		<!-- 公司简介 -->
		<div style="width: 1251px;height: 447px;margin: 0 auto;margin-top: -200px;position: relative;">
			<div class="company fcs">
				<div class="info">
					<div class="title" style="font-weight: bold;">公司简介</div>
					<div class="hr"></div>
					<div class="p s1" style="font-size: 16px;line-height: 32px;">
						{{obj.pc_jianjie}}
					</div>
				</div>
				<img src="../assets/jianjie.png" alt="">
			</div>
		</div>
		<!-- 业务范围 -->
		<div class="work">
			<div class="titlebox fcs">
				<div class="left">业务范围</div>
				<div class="right">
					<div class="fc">
						<div class="t1">我们宗旨</div>
						<div class="t2">快递、安全、有保障</div>
					</div>
					<div class="fc">
						<div class="t1">我们愿景</div>
						<div class="t2">让您的快递迅速、马不停蹄的到家</div>
					</div>
				</div>
			</div>
			<div class="p">
				{{obj.pc_business}}
			</div>
		</div>
		<!-- 发展前景 -->
		<div class="develop">
			<div class="title" style="font-weight: bold;">发展前景</div>
			<div class="titles">DEVELOPMENT PROSPECT</div>
			<div class="hr"></div>
			<div class="p">
				{{obj.pc_develop}}
			</div>
		</div>
		<!-- 企业文化 -->
		<div class="civilization">
			<div class="titles">THE ENTERPRISE CULTURE</div>
			<div class="title" style="font-weight: bold;">企业文化</div>
			<div class="fl-r">
				<div class="fl-c"  v-for="(item,index) in obj.pc_culture" :key="index">
					<img style="width: 44px;height: 46px;" :src="item.icon" alt="">
					<div class="siae1">{{item.name}}</div>
					<div class="siae2">{{item.val}}</div>
				</div>
				
			</div>
		</div>
		<!-- 联系我们 -->
		<div class="info_titles">CONTACT US</div>
		<div class="info_title" style="font-weight: bold;">联系我们</div>
		<div class="infobox">
			<div class="con">
				<div class="p1">可以联系我们在下方留下你们的联系方式</div>
				<div class="p2">我们的联系电话：{{obj.phone}}</div>
				<div class="p3 fcs">
					<div class="item fc">
						<img src="../assets/yx.png" alt="">
						<div>邮箱：{{obj.email}}</div>
					</div>
					<div class="item fc">
						<img src="../assets/wz.png" alt="">
						<div>网址：{{obj.wangzhi}}</div>
					</div>
				</div>
				<div class="p4">
					<img :src="obj.qr_code" alt="">
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		data() {
			return {
				obj:{}
			}
		},
		created() {
			this.chilelist()
		},
		methods: {
			 chilelist(){
				this.$https
					.get(`/api/pc/about`,)
					.then((res) => {
					res.data.qr_code='https://www.idongdian.com'+res.data.qr_code
					console.log(res);
					this.obj=res.data

			
        	})
			 }
		}
	}
</script>

<style scoped>
	.fl-r{
		margin-top: 25px;
		display: flex;
		align-items: center;
		justify-content: space-around;
		
	}
	.fl-c{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.siae1{
		margin-top: 17px;
		font-size: 25px;
	
		font-weight: normal;
		color: #333333;
	}
	.siae2{
		margin-top: 13px;
		font-size: 19px;

		font-weight: normal;
		color: #999999;
	}
	.s1{

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 7;
			-webkit-box-orient: vertical;
	}
	.company {
		width: 1251px;
		height: 447px;
		background: #FFFFFF;
		box-shadow: 0px 21px 59px 1px rgba(0, 0, 0, 0.21);
		border-radius: 9px;
		position: absolute;
		top: 0;
		left: 0;

	}

	.company .info {
		width: 630px;
		height: 447px;
		box-sizing: border-box;
		padding: 24px 66px;
		
	}

	.company .info .title {
		font-size: 48px;
		color: #000;
	}

	.company .info .hr {
		width: 64px;
		height: 3px;
		background: #2B6CDB;
		border-radius: 2px;
		margin: 16px 0 24px 0;
	}

	.company .info .p {
		font-size: 22px;
		color: #555555;
		line-height: 43px;
		
		
	}

	.company img {
		width: 625px;
		height: 447px;
	}

	.work {
		width: 1200px;
		margin: 100px auto;
	}

	.work .titlebox {
		width: 846px;
		margin: 0 auto;
	}

	.work .titlebox .left {
		font-size: 48px;
		color: #191919;
		font-weight: bold;
	}

	.work .titlebox .right {
		width: 571px;
		height: 175px;
		background: #0572E1;
		color: #ffffff;
		box-sizing: border-box;
		padding: 30px 52px;
	}

	.work .titlebox .right .t1 {
		font-size: 24px;
		margin: 12px 0;
		margin-right: 24px;
	}

	.work .titlebox .right .t2 {
		font-size: 16px;
	}

	.work .p {
		margin-top: 80px;
		font-size: 16px;
		line-height: 34px;
	}

	.develop {
		width: 1200px;
		margin: 0 auto;
		position: relative;
	}

	.develop .title {
		font-size: 48px;
		color: #131A21;
		text-align: center;
	}

	.develop .titles {
		font-size: 63px;
		color: #131A21;
		opacity: 0.1;
		position: absolute;
		top: 0;
		left: 0;
	}

	.develop .hr {
		width: 40px;
		height: 4px;
		background: #CF02B4;
		margin: 0 auto;
	}

	.develop .p {
		width: 1200px;
		margin: 0 auto;
		margin-top: 80px;
		font-size: 16px;
		line-height: 34px;
	}

	.civilization {
		width: 1200px;
		margin: 100px auto;
	}

	.civilization .titles {
		font-size: 28px;
		color: #323232;
		text-align: center;
	}

	.civilization .title {
		font-size: 48px;
		color: #333333;
		text-align: center;
	}

	.civilization .p {
		width: 1040px;
		margin: 0 auto;
		margin-top: 80px;
		font-size: 16px;
		line-height: 34px;
	}
	
	.info_titles{
		font-size: 26px;
		color: #323232;
		text-align: center;
	}
	
	.info_title{
		font-size: 48px;
		color: #333333;
		text-align: center;
	}

	.infobox {
		width: 100%;
		height: 412px;
		background: url('../assets/lxbg.png') no-repeat;
		background-size: 100% 100%;
		margin-top: 63px;
	}

	.infobox .con {
		width: 1200px;
		margin: 0 auto;
		box-sizing: border-box;
		padding-top: 56px;
	}

	.infobox .p1 {
		font-size: 17px;
		color: #FFFFFF;
		text-align: center;
	}

	.infobox .p2 {
		font-size: 32px;
		color: #3781FF;
		text-align: center;
		margin-top: 30px;
	}

	.infobox .p3 {
		width: 500px;
		font-size: 16px;
		color: #ffffff;
		margin: 0 auto;
		margin-top: 25px;
	}

	.infobox .p3 img {
		width: 18px;
		height: 18px;
		margin-right: 10px;
	}

	.infobox .p4 {
		width: 120px;
		height: 120px;
		display: block;
		margin: 0 auto;
		margin-top: 44px;
	}

	.infobox .p4 img {
		width: 120px;
		height: 120px;
	}
</style>